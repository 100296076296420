<template>
  <div class="flex flex-col justify-end h-[100dvh] px-12 py-24">
    <div class="desk:max-w-440">
      <div class="type-headline-lg mb-12">{{ $t('error500.headline') }}</div>
      <div class="mb-12 type-base">{{ $t('error500.subheadline') }}</div>
      <div class="mb-36">
        <button class="btn inline-block" @click="handleError">{{ $t('error500.button') }}</button>
      </div>
      <div class="type-sm mb-18 withUnderline">
        <img src="/icons/refresh.svg" class="w-20 inline-block mr-12">
        <span v-html="$t('error500.try1')" />
      </div>
      <div class="type-sm mb-18 withUnderline">
        <img src="/icons/505.svg" class="w-20 inline-block mr-12">
        <span v-html="$t('error500.try2')" />
      </div>
      <div class="type-sm mb-80 withUnderline">
        <img src="/icons/mail.svg" class="w-20 inline-block mr-12">
        <span v-html="$t('error500.try3')" />
      </div>
    </div>

    <div>
      <img
        src="/logos/session-logo_dark.svg"
        class="w-full"
        alt="Session.map logo"
      >
    </div>
  </div>
</template>

<script setup lang="ts">
defineProps({
  error: Object,
});
const handleError = () => clearError({ redirect: '/' });

</script>

<style scoped lang="postcss">
.withUnderline:deep(a) {
    @apply underline;
}
</style>
