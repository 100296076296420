import { default as _91_46_46_46slug_93Sb3zmP9EPhMeta } from "/opt/atlassian/pipelines/agent/build/pages/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_9354nczZotEwMeta } from "/opt/atlassian/pipelines/agent/build/pages/account/[...slug].vue?macro=true";
import { default as invitesh3fH9IhD0Meta } from "/opt/atlassian/pipelines/agent/build/pages/account/invite.vue?macro=true";
import { default as _91token_93Uhbu4F3f6OMeta } from "/opt/atlassian/pipelines/agent/build/pages/account/resetpassword/[email]/[token].vue?macro=true";
import { default as signinVluGW1NUByMeta } from "/opt/atlassian/pipelines/agent/build/pages/account/signin.vue?macro=true";
import { default as indexyX2Uc3TPv9Meta } from "/opt/atlassian/pipelines/agent/build/pages/account/signup/index.vue?macro=true";
import { default as successtimVbjpAiVMeta } from "/opt/atlassian/pipelines/agent/build/pages/account/signup/success.vue?macro=true";
import { default as welcome81hKZX3sklMeta } from "/opt/atlassian/pipelines/agent/build/pages/account/signup/welcome.vue?macro=true";
import { default as barcodeF5NAN4dnO3Meta } from "/opt/atlassian/pipelines/agent/build/pages/barcode.vue?macro=true";
import { default as barcodelookupRFn0mZfdOsMeta } from "/opt/atlassian/pipelines/agent/build/pages/barcodelookup.vue?macro=true";
import { default as _91cartId_93N4JwB39OlbMeta } from "/opt/atlassian/pipelines/agent/build/pages/checkout/[result]/[cartId].vue?macro=true";
import { default as indexqJXazFsVc0Meta } from "/opt/atlassian/pipelines/agent/build/pages/checkout/index.vue?macro=true";
import { default as _91basketId_93zsXe4GycyRMeta } from "/opt/atlassian/pipelines/agent/build/pages/checkout/pointshop/[basketId].vue?macro=true";
import { default as formValidationTemplateVVH0F5HDjYMeta } from "/opt/atlassian/pipelines/agent/build/pages/formValidationTemplate.vue?macro=true";
import { default as _91_46_46_46slug_93PPctWcsUhoMeta } from "/opt/atlassian/pipelines/agent/build/pages/salestool/[...slug].vue?macro=true";
import { default as searchHZkt4O5LeJMeta } from "/opt/atlassian/pipelines/agent/build/pages/search.vue?macro=true";
import { default as tailwindfixesJKo63NYaaBMeta } from "/opt/atlassian/pipelines/agent/build/pages/tailwindfixes.vue?macro=true";
import { default as test1g9xwIZN1aMeta } from "/opt/atlassian/pipelines/agent/build/pages/test.vue?macro=true";
import { default as test500cQJHVOaDtlMeta } from "/opt/atlassian/pipelines/agent/build/pages/test500.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "account-slug",
    path: "/account/:slug(.*)*",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "account-invite",
    path: "/account/invite",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/invite.vue").then(m => m.default || m)
  },
  {
    name: "account-resetpassword-email-token",
    path: "/account/resetpassword/:email()/:token()",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/resetpassword/[email]/[token].vue").then(m => m.default || m)
  },
  {
    name: "account-signin",
    path: "/account/signin",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/signin.vue").then(m => m.default || m)
  },
  {
    name: "account-signup",
    path: "/account/signup",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/signup/index.vue").then(m => m.default || m)
  },
  {
    name: "account-signup-success",
    path: "/account/signup/success",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/signup/success.vue").then(m => m.default || m)
  },
  {
    name: "account-signup-welcome",
    path: "/account/signup/welcome",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/signup/welcome.vue").then(m => m.default || m)
  },
  {
    name: "barcode",
    path: "/barcode",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/barcode.vue").then(m => m.default || m)
  },
  {
    name: "barcodelookup",
    path: "/barcodelookup",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/barcodelookup.vue").then(m => m.default || m)
  },
  {
    name: "checkout-result-cartId",
    path: "/checkout/:result()/:cartId()",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/checkout/[result]/[cartId].vue").then(m => m.default || m)
  },
  {
    name: "checkout",
    path: "/checkout",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout-pointshop-basketId",
    path: "/checkout/pointshop/:basketId()",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/checkout/pointshop/[basketId].vue").then(m => m.default || m)
  },
  {
    name: "formValidationTemplate",
    path: "/formValidationTemplate",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/formValidationTemplate.vue").then(m => m.default || m)
  },
  {
    name: "salestool-slug",
    path: "/salestool/:slug(.*)*",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/salestool/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "search",
    path: "/search",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "tailwindfixes",
    path: "/tailwindfixes",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/tailwindfixes.vue").then(m => m.default || m)
  },
  {
    name: "test",
    path: "/test",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/test.vue").then(m => m.default || m)
  },
  {
    name: "test500",
    path: "/test500",
    meta: test500cQJHVOaDtlMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/test500.vue").then(m => m.default || m)
  }
]