<template>
  <div class="LoadingIcon inline-block">
    <img
      src="/icons/spinner.svg"
      class="h-18 w-18 spin inline-block"
      :alt="$t('loading')"
    >
    <slot />
  </div>
</template>

<script setup lang="ts">

</script>

<style scoped lang="postcss">
</style>
